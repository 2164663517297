var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "boxed-layout", staticStyle: { "max-width": "unset" } },
    [
      _c("h2", [
        _c(
          "span",
          {
            attrs: {
              id: "user-management-page-heading",
              "data-cy": "userManagementPageHeading",
            },
          },
          [_vm._v("Users")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex justify-content-end" },
          [
            _c("router-link", {
              attrs: { custom: "", to: { name: "GenUserCreate" } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ navigate }) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary create-entity",
                          on: { click: navigate },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "plus" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Create a new User")]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.users
        ? _c(
            "div",
            { staticClass: "table-responsive" },
            [
              _c(
                "table",
                {
                  staticClass: "table table-striped",
                  attrs: { "aria-describedby": "Users" },
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          attrs: { scope: "col" },
                          on: {
                            click: function ($event) {
                              return _vm.changeOrder("id")
                            },
                          },
                        },
                        [_c("span", [_vm._v("ID")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          attrs: { scope: "col" },
                          on: {
                            click: function ($event) {
                              return _vm.changeOrder("login")
                            },
                          },
                        },
                        [_c("span", [_vm._v("Login")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          attrs: { scope: "col" },
                          on: {
                            click: function ($event) {
                              return _vm.changeOrder("email")
                            },
                          },
                        },
                        [_c("span", [_vm._v("Email")])]
                      ),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }),
                      _vm._v(" "),
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          attrs: { scope: "col" },
                          on: {
                            click: function ($event) {
                              return _vm.changeOrder("createdDate")
                            },
                          },
                        },
                        [_c("span", [_vm._v("Created Date")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          attrs: { scope: "col" },
                          on: {
                            click: function ($event) {
                              return _vm.changeOrder("lastModifiedBy")
                            },
                          },
                        },
                        [_c("span", [_vm._v("Last Modified By")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          attrs: { scope: "col", id: "modified-date-sort" },
                          on: {
                            click: function ($event) {
                              return _vm.changeOrder("lastModifiedDate")
                            },
                          },
                        },
                        [_c("span", [_vm._v("Last Modified Date")])]
                      ),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.users
                    ? _c(
                        "tbody",
                        _vm._l(_vm.users, function (user) {
                          return _c(
                            "tr",
                            { key: user.id, attrs: { id: user.login } },
                            [
                              _c(
                                "td",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "GenUserView",
                                          params: { userId: user.login },
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(user.id))]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(user.login))]),
                              _vm._v(" "),
                              _c("td", { staticClass: "jhi-user-email" }, [
                                _vm._v(_vm._s(user.email)),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                !user.activated
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-danger btn-sm deactivated",
                                        on: {
                                          click: function ($event) {
                                            return _vm.setActive(user, true)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              Deactivated\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                user.activated
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-success btn-sm",
                                        attrs: {
                                          disabled: _vm.username === user.login,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setActive(user, false)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              Activated\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                _vm._l(user.authorities, function (authority) {
                                  return _c("div", { key: authority }, [
                                    _c(
                                      "span",
                                      { staticClass: "badge badge-info" },
                                      [_vm._v(_vm._s(authority))]
                                    ),
                                  ])
                                }),
                                0
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm._f("formatDate")(user.createdDate))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(user.lastModifiedBy))]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDate")(user.lastModifiedDate)
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _c(
                                  "div",
                                  { staticClass: "btn-group" },
                                  [
                                    _c("router-link", {
                                      attrs: {
                                        to: {
                                          name: "GenUserEdit",
                                          params: { userId: user.login },
                                        },
                                        custom: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ navigate }) {
                                              return [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-primary btn-sm edit",
                                                    on: { click: navigate },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: "pencil-alt",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-none d-md-inline",
                                                      },
                                                      [_vm._v("Edit")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "btn btn-sm delete",
                                        attrs: {
                                          variant: "danger",
                                          disabled: _vm.username === user.login,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.prepareRemove(user)
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "times" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "d-none d-md-inline" },
                                          [_vm._v("Delete")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-modal",
                {
                  ref: "removeUser",
                  attrs: { id: "removeUser" },
                  on: {
                    ok: function ($event) {
                      return _vm.deleteUser()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "modal-body" }, [
                    _c("p", { attrs: { id: "gen-delete-user-heading" } }, [
                      _vm._v("Are you sure you want to delete this user?"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog()
                            },
                          },
                        },
                        [_vm._v("\n          Abbrechen\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button", id: "confirm-delete-user" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteUser()
                            },
                          },
                        },
                        [_vm._v("\n          Delete\n        ")]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.users && _vm.users.length > 0,
              expression: "users && users.length > 0",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "row justify-content-center" },
            [
              _c("b-pagination", {
                attrs: {
                  size: "md",
                  "total-rows": _vm.totalItems,
                  "per-page": _vm.itemsPerPage,
                  change: _vm.loadPage(_vm.page),
                },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", { attrs: { scope: "col" } }, [
      _c("span", [_vm._v("Profiles")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }